<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="this.size.width"
    >
      <v-card class="rounded-xl pa-4">
        <v-card-title class="text-h5 " id="title">
          <v-icon class="pe-1" color="secondary" size="40" v-if="this.icon">
            {{ icon }}
          </v-icon>
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-form ref="formular">
            <div v-for="data in values" :key="data.index">
              <AutoComplete
                  v-if="data.type === 'autoComplete'"
                  :data="data"
                  @sendData="sendData($event)"
                  @newEntry="newEntry($event)"
                  :label="data.title"
              ></AutoComplete>
              <TextField
                  v-if="data.type === 'string'"
                  :data="data"
                  @sendData="sendData($event)"
                  :label="data.title"
              />
              <Checkbox
                  v-if="data.type === 'boolean'"
                  :data="data"
                  @sendData="sendData($event)"
              />
              <DatePicker
                  v-if="data.type === 'date'"
                  :data="data"
                  @sendData="sendData($event)"
              ></DatePicker>
              <DateTimePicker
                  v-if="data.type === 'dateTime'"
                  :data="data"
                  @sendData="sendData($event)"
              ></DateTimePicker>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="resetForm"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              @click="editBusinessInfo"
          >
            {{ successButton }}
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>

  </div>
</template>

<script>
import TextField from "@/components/dialog/fields/TextField";
import Checkbox from "@/components/dialog/fields/Checkbox";
import AutoComplete from "@/components/dialog/fields/AutoComplete";
import DatePicker from "@/components/dialog/fields/DatePicker";
import DateTimePicker from "@/components/dialog/fields/DateTimePicker";

//TODO: Bei Klick auf enter -> emit

/**
 * Dialog to edit business information depending on category of vendor
 * @displayName Dialog
 */
export default {
  name: "Dialog",
  components: {
    Checkbox,
    TextField,
    AutoComplete,
    DatePicker,
    DateTimePicker
  },
  props: {
    /**
     * Contains the title for the dialog
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Contains if the dialog is visible or not
     * @values true, false
     */
    dialog: {
      type: Boolean,
      default: false
    },
    /**
     * Contains all values to display input areas
     */
    values: {
      type: Array,
      default() {
        return []
      }
    },
    size: {
      type: Object,
      default() {
        return {
          width: 500,
          height: 'auto'
        }
      }
    },
    /**
     * Display Text of the finish Button
     */
    successButton: {
      type: String,
      default: ''
    },
    /**
     * Classname of the Icon to display in HeadLine
     */
    icon: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      newValue: {},
    }
  },
  computed: {
    newValues() {
      return this.values
    },
  },
  methods: {
    /**
     * Edits the business information
     * @public
     */
    editBusinessInfo() {
      /**
       * Emits the edited business information
       * @property {Object} businessInfo contains the edited business information
       */
      this.$emit('emitNewData', this.newValues)
      this.$refs.formular.reset()
    },
    /**
     * Stops editing the business information
     * @public
     */
    resetForm() {
      this.$refs.formular.reset()
      /**
       * Emits to hide the dialog
       */
      this.$emit('hideDialog')
    },
    /**
     * The returned value of textfield or checkbox will be edited in new values Object
     * @param {Object} payload contains the edited business information
     */
    sendData(payload) {
      const index = this.newValues.findIndex(item => item.id === payload.id)
      this.newValues[index] = payload
    },

    newEntry(payload) {
      this.$emit('createEntry', {entity: payload.entity})
    },
  }
}
</script>

<style scoped>

</style>
