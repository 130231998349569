<template>
  <div>
    <v-datetime-picker
        :label="data.title"
        v-model="data.value"
        @keyup="sendData($event)"
        @input="sendData($event)"
        :datePickerProps="{locale:'de-de', label: 'test'}"
        :timePickerProps="{format:'24hr'}"
        :okText="'Wählen'"
        :clearText="'Abbrechen'"
        dateIcon="mdi-check"

    >
      <template slot="dateIcon">
        <v-icon>mdi-calendar</v-icon>
      </template>
      <template slot="timeIcon">
        <v-icon>mdi-clock</v-icon>
      </template>
    </v-datetime-picker>
  </div>

</template>

<script>
/**
 * Displays a input field to change business information
 * @displayName TextField
 */
export default {
  name: "DatePicker",
 data(){
    return{
      menu: false,
      rule: {
        required: value => !!value || 'Erforderlich',
      }
    }
 },
  props: {
    id:{
      type:String,
      default(){
        return ''
      }
    },
    /**
     * Contains the data for the text field
     */
    data: {
      type: Object,
      default(){
        return {}
      }
    },
    rules: {
      type:Array,
      default(){
        return []
      }
    },
  },
  computed: {
    inputValue(){
      return {...this.data}
    }
  },
  methods: {
    /**
     * Sends the changed data for the text field to the parent
     * @public
     */
    sendData(){
      /**
       * Sends the text field value to the parent
       * @property {Object} data contains the new inputValue
       */
      this.$emit('sendData', this.inputValue)
    }
  }
}
</script>
