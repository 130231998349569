<template>
  <div class="row ps-0" v-if="data.icon">
    <div class="col-10">
      <v-autocomplete
          filled
          :data="data"
          :label="data.title"
          @change="sendData(data)"
          :items="data.items"
          item-text="text"
          item-value="value"
          v-model="data.value"
          :value="data.value"
      ></v-autocomplete>
    </div>
    <div class="col-2 pt-8">
      <v-tooltip top color="secondary">
        <template v-slot:activator="{ on, attrs }">
        <span
            v-bind="attrs"
            v-on="on"
        >
          <a @click="newEntry(data.entity)">
        <v-icon class="pe-1 d-inline-flex" color="primary"
        >
          {{ data.icon }}
        </v-icon>
      </a>

        </span>
        </template>
        {{getTooltip(data.entity)}}
      </v-tooltip>


    </div>
  </div>
  <div class="row ps-0" v-else>
    <div class="col-12">
      <v-autocomplete
          :data="data"
          :label="data.title"
          @change="sendData(data)"
          :items="data.items"
          v-model="data.value"
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
/**
 * Displays a input field to change business information
 * @displayName TextField
 */
export default {
  name: "TextField",
  props: {
    /**
     * Contains the data for the text field
     */
    data: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    inputValue() {
      return {...this.data}
    }
  },
  methods: {
    /**
     * Sends the changed data for the text field to the parent
     * @public
     */
    sendData() {
      /**
       * Sends the text field value to the parent
       * @property {Object} data contains the new inputValue
       */
      this.$emit('sendData', this.inputValue)
    },
    newEntry(entity) {
      this.$emit('newEntry', {entity: entity})
    },
    getTooltip(entity){
      switch(entity){
        case'wedding':
          return 'Neue Hochzeit erstellen'
        case'task':
          return 'Neue Aufgabe erstellen'
        case'customer':
          return 'Neuen Kunden erstellen'
        case'vendor':
          return 'Neuen Dienstleister erstellen'
        case'event':
          return 'Neuen Termin erstellen'
      }
    }
  }
}
</script>
