<template>
<div>
  <v-text-field
      v-model="inputValue.value"
      :label="inputValue.title"
      @keyup="sendData()"
      :rules="[rule.required]"
      v-if="rules.includes('required')"
      :readonly="readonly"
  >
  </v-text-field>
  <v-text-field
      v-model="inputValue.value"
      :label="inputValue.title"
      @keyup="sendData()"
      :rules="[rule.required]"
      v-else-if="readonly"
      :disabled="true"
      :readonly="readonly"
  >
  </v-text-field>
  <v-text-field
      v-model="inputValue.value"
      :label="inputValue.title"
      @keyup="sendData()"
      :readonly="readonly"
      :type="type"
      v-else
  >
  </v-text-field>
</div>

</template>

<script>
/**
 * Displays a input field to change business information
 * @displayName TextField
 */
export default {
  name: "TextField",
  data(){
    return {
      rule: {
        required: value => !!value || 'Erforderlich',
      }
    }
  },
  props: {
    /**
     * Contains the data for the text field
     */
    data: {
      type: Object,
      default(){
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default(){
        return false
      }
    },
    rules: {
      type:Array,
      default(){
        return []
      }
    },
    type: String
  },
  computed: {
    inputValue(){
      return {...this.data}
    }
  },
  methods: {
    /**
     * Sends the changed data for the text field to the parent
     * @public
     */
    sendData(){
      /**
       * Sends the text field value to the parent
       * @property {Object} data contains the new inputValue
       */
      this.$emit('sendData', this.inputValue)
    }
  }
}
</script>
