<template>
  <v-checkbox
      :label="checkBoxValue.title"
      :input-value="checkBoxValue.value"
      v-model="checkBoxValue.value"
      @click="sendData"
  >
  </v-checkbox>
</template>

<script>
/**
 * Component for a checkbox
 * @displayName Checkbox
 */
export default {
  name: "Checkbox",
  props: {
    /**
     * Contains the data for the checkbox
     */
    data: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    checkBoxValue(){
      return {...this.data}
    }
  },
  methods: {
    /**
     * Send the value of the checkbox to the parent
     * @public
     */
    sendData(){
      /**
       * Sends the checkbox value to the parent
       * @property {Object} data contains the new inputValue
       */
      this.$emit('sendData',  this.checkBoxValue)
    }
  }
}
</script>