<template>
  <div>

    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="data.value"
            :label="data.title"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          max-width="700"
          :data="data"
          @change="sendData($event)"
          v-model="data.value"
          locale="de-de"
          type="date"
          @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
/**
 * Displays a input field to change business information
 * @displayName TextField
 */
export default {
  name: "DatePicker",
 data(){
    return{
      menu: false
    }
 },
  props: {
    /**
     * Contains the data for the text field
     */
    data: {
      type: Object,
      default(){
        return {}
      }
    },
    rules: {
      type:Array,
      default(){
        return []
      }
    },
  },
  computed: {
    inputValue(){
      return {...this.data}
    }
  },
  methods: {
    /**
     * Sends the changed data for the text field to the parent
     * @public
     */
    sendData(){
      /**
       * Sends the text field value to the parent
       * @property {Object} data contains the new inputValue
       */
      this.$emit('sendData', this.inputValue)
    }
  }
}
</script>
